function fill(str) {
  if ("".concat(str).length === 1) return "0" + str;
  return str;
}

module.exports = {
  /**
   * Dyb sammenligning; afhænger af rækkefølge!
   */
  deepEquals: function deepEquals(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },

  /**
   * Returnerer nyt objekt med null erstattet med tom streng, rekursiv
   */
  nulls2Empty: function recur(obj) {
    if (obj === null) return "";
    if (obj instanceof Object && !(obj instanceof Array)) return Object.keys(obj).reduce(function (newObj, key) {
      newObj[key] = recur(obj[key]);
      return newObj;
    }, {});
    return obj;
  },
  isEmpty: function isEmpty(obj) {
    return obj === null || obj === undefined;
  },
  isEmptyArray: function isEmptyArray(obj) {
    return obj === null || obj === undefined || obj.length === 0;
  },
  isEmptyStr: function isEmptyStr(obj) {
    return obj === null || obj === undefined || obj.trim() === "";
  },
  isEmptyOrZero: function isEmptyOrZero(obj) {
    return obj === null || obj === undefined || obj === 0;
  },
  getStringAorB: function getStringAorB(str1, str2) {
    if (str1 === null || str1 === undefined || str1.trim() === "") return str2 === null || str2 === undefined ? "" : str2;
    return str1;
  },
  formatDate: function formatDate(ldt) {
    if (ldt === null) return "";
    var date = new Date(ldt);
    return fill(date.getDate()) + "-" + fill(date.getMonth() + 1) + "-" + date.getFullYear() + " " + fill(date.getHours()) + ":" + fill(date.getMinutes());
  }
};