module.exports = {

  // Local storage
  ORGANISATION: 'ORGANISATION',
  BRUGER: 'BRUGER',

  sessionThreshold: 1000 * 60 * 5,

  // URLs
  basename: '/tilslutning/',
  urlUdbyderSystemer: '/udbydersystemadm/',
  urlStilServices: '/stilserviceadm/',
  urlDataadgange: '/dataadgangadm/',
  urlRestinstitution: '/restinstitutionadm/',
  urlUdbyder: '/udbyderadm/',
  urlSupportvaerktoej: '/supportvaerktoej/',
  urlBELogin: '/tilslutningBE/oidc/login',
  urlBELogout: '/tilslutningBE/logout',
  urlLogout: '/logout',
  urlLogin: '/login',
  urlBasicLogin: '/tilslutningBE/basiclogin',
  urlVersion: '/version',
  urlBruger: '/tilslutningBE/brugeroplysninger',

  // Parametre:
  postLogoutParam: 'logout',
  selectOrganisationParam: 'select-organisation',
  bekraeftNotifikationsemailParam: 'notifikationsemail',
  fornyKontaktpersonParam: 'kontaktperson',

  // Actions
  NULSTIL_ALERTS: 'NULSTIL_ALERTS',

  // Forside actions
  TOKEN_REFRESH: "TOKEN_REFRESH",
  // Login/out actions
  LOGIN_CLEAR: "LOGIN_CLEAR",
  SELECT_ORGANISATION: "SELECT_ORGANISATION",
  OPEN_ORGANISATION_MODAL: "OPEN_ORGANISATION_MODAL",
  SET_SELECT_ORGANISATION_FEJL: "SET_SELECT_ORGANISATION_FEJL",
  HENT_INSTITUTIONER_SUCCESS: "HENT_INSTITUTIONER_SUCCESS",
  HENT_INSTITUTIONER_FEJL: "HENT_INSTITUTIONER_FEJL",
  HENT_NOTIFIKATIONER_SUCCESS: "HENT_NOTIFIKATIONER_SUCCESS",
  HENT_NOTIFIKATIONER_FEJL: "HENT_NOTIFIKATIONER_FEJL",
  HENT_REST_ORGANISATIONER_SUCCESS: "HENT_REST_ORGANISATIONER_SUCCESS",
  HENT_REST_ORGANISATIONER_FEJL: "HENT_REST_ORGANISATIONER_FEJL",
  HENT_UDBYDERE_SUCCESS: "HENT_UDBYDERE_SUCCESS",
  HENT_UDBYDERE_FEJL: "HENT_UDBYDERE_FEJL",
  HENT_DAGTILBUD_SUCCESS: "HENT_DAGTILBUD_SUCCESS",
  HENT_DAGTILBUD_FEJL: "HENT_DAGTILBUD_FEJL",
  VIS_SESSION_ADVARSEL: "VIS_SESSION_ADVARSEL",
  SKJUL_SESSION_ADVARSEL: "SKJUL_SESSION_ADVARSEL",
  FORNY_JWT_SUCCESS: "FORNY_JWT_SUCCESS",
  FORNY_JWT_FEJL: "FORNY_JWT_FEJL",
  VIS_LOGIN_MODAL: "VIS_LOGIN_MODAL",
  SKJUL_LOGIN_MODAL: "SKJUL_LOGIN_MODAL",
  BASIC_LOGIN_SUCCESS: "BASIC_LOGIN_SUCCESS",
  BASIC_LOGIN_FEJL: "BASIC_LOGIN_FEJL",
  BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS: "BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS",
  BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL: "BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL",
  FORNY_KONTAKTPERSON_SUCCESS: "FORNY_KONTAKTPERSON_SUCCESS",
  FORNY_KONTAKTPERSON_FEJL: "FORNY_KONTAKTPERSON_FEJL",

  // Version actions
  GET_FRONTEND_VERSION_SUCCESS: "GET_FRONTEND_VERSION_SUCCESS",
  GET_FRONTEND_VERSION_FEJL: "GET_FRONTEND_VERSION_FEJL",
  GET_BACKEND_VERSION_SUCCESS: "GET_BACKEND_VERSION_SUCCESS",
  GET_BACKEND_VERSION_FEJL: "GET_BACKEND_VERSION_FEJL",
  GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_SUCCESS: "GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_SUCCESS",
  GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_FEJL: "GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_FEJL",
  GET_UDBYDERSYSTEMADM_BACKEND_VERSION_SUCCESS: "GET_UDBYDERSYSTEMADM_BACKEND_VERSION_SUCCESS",
  GET_UDBYDERSYSTEMADM_BACKEND_VERSION_FEJL: "GET_UDBYDERSYSTEMADM_BACKEND_VERSION_FEJL",
  GET_STILSERVICEADM_FRONTEND_VERSION_SUCCESS: "GET_STILSERVICEADM_FRONTEND_VERSION_SUCCESS",
  GET_STILSERVICEADM_FRONTEND_VERSION_FEJL: "GET_STILSERVICEADM_FRONTEND_VERSION_FEJL",
  GET_STILSERVICEADM_BACKEND_VERSION_SUCCESS: "GET_STILSERVICEADM_BACKEND_VERSION_SUCCESS",
  GET_STILSERVICEADM_BACKEND_VERSION_FEJL: "GET_STILSERVICEADM_BACKEND_VERSION_FEJL",
  GET_DATAADGANGADM_FRONTEND_VERSION_SUCCESS: "GET_DATAADGANGADM_FRONTEND_VERSION_SUCCESS",
  GET_DATAADGANGADM_FRONTEND_VERSION_FEJL: "GET_DATAADGANGADM_FRONTEND_VERSION_FEJL",
  GET_DATAADGANGADM_BACKEND_VERSION_SUCCESS: "GET_DATAADGANGADM_BACKEND_VERSION_SUCCESS",
  GET_DATAADGANGADM_BACKEND_VERSION_FEJL: "GET_DATAADGANGADM_BACKEND_VERSION_FEJL",
  GET_RESTINSTITUTIONADM_FRONTEND_VERSION_SUCCESS: "GET_RESTINSTITUTIONADM_FRONTEND_VERSION_SUCCESS",
  GET_RESTINSTITUTIONADM_FRONTEND_VERSION_FEJL: "GET_RESTINSTITUTIONADM_FRONTEND_VERSION_FEJL",
  GET_RESTINSTITUTIONADM_BACKEND_VERSION_SUCCESS: "GET_RESTINSTITUTIONADM_BACKEND_VERSION_SUCCESS",
  GET_RESTINSTITUTIONADM_BACKEND_VERSION_FEJL: "GET_RESTINSTITUTIONADM_BACKEND_VERSION_FEJL",
  GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_SUCCESS: "GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_SUCCESS",
  GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_FEJL: "GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_FEJL",
  GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_SUCCESS: "GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_SUCCESS",
  GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_FEJL: "GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_FEJL",

  // Organisationstyper - skal matche tilslutningBE.OrgType
  typeInst: 'INSTITUTION',
  typeUdbyder: 'UDBYDER',

  // Adgangskontrol - skal matche tilslutningBE.Delsystemer
  systemUdbydersystemAdm: 'UDBYDERSYSTEMADM',
  systemStilserviceAdm: 'STILSERVICEADM',
  systemStilserviceIntern: 'STILSERVICEINTERN',
  systemDataadgangAdm: 'DATAADGANGADM',
  systemRestInstitutionAdm: 'RESTINSTITUTIONADM',
  systemUdbyderAdm: 'UDBYDERADM',
  systemSupportVaerktoej: 'SUPPORTVAERKTOEJ',

  // Statusser
  status_login: 'Logger ind',
  status_login_fejl: 'Fejl i login',
  status_nulstiller_alerts: 'Nulstiller alerts',
  status_forny_jwt_successs: 'JWT er fornyet',
  status_vis_login_modal: "Viser login modal",
  status_skjul_login_modal: "Skjuler loginmodal",
  status_basic_login_success: "Er logget ind med brugernavn og password",
  status_basic_login_error: "Er logget ind med brugernavn og password",

  // STIL
  STIL_CVR: "13223459",

  // api points
  apiSkiftOrganisation: '/tilslutningBE/active-organisation',
  apiHentOrganisationerInstitutioner: '/tilslutningBE/organisationer/institutioner',
  apiHentRestInstitutioner: '/tilslutningBE/organisationer/restinstitutioner',
  apiHentOrganisationerUdbydere: '/tilslutningBE/organisationer/udbydere',
  apiHentOrganisationerDagtilbud: '/tilslutningBE/organisationer/dagtilbud',
  apiBasicLogin: '/tilslutningBE/basiclogin',
  apiBekraeftNotifikationsemail: '/tilslutningBE/notifikationsemails/bekraeft',
  apiFornyKontaktperson: '/tilslutningBE/kontaktpersoner/forny',
  apiFornyJwt: '/tilslutningBE/brugeroplysninger',
  apiHentNotifikationer: '/tilslutningBE/notifikationer/hent',

  // versions
  forsideFrontendVersionUrl: '/tilslutning/version.json',
  forsideBackendVersionUrl: '/tilslutningBE/version',
  udbydersystemadmFrontendVersionUrl: '/udbydersystemadm/version.json',
  udbydersystemadmBackendVersionUrl: '/udbydersystemadmBE/version',
  stilserviceadmFrontendVersionUrl: '/stilserviceadm/version.json',
  stilserviceadmBackendVersionUrl: '/stilserviceadmBE/version',
  dataadgangadmFrontendVersionUrl: '/dataadgangadm/version.json',
  dataadgangadmBackendVersionUrl: '/dataadgangadmBE/version',
  restinstitutionadmFrontendVersionUrl: '/restinstitutionadm/version.json',
  restinstitutionadmBackendVersionUrl: '/restinstitutionadmBE/version',
  supportvaerktoejFrontendVersionUrl: '/supportvaerktoej/version.json',
  supportvaerktoejBackendVersionUrl: '/supportvaerktoejBE/version',

  // State
  loginStateInit: {
    token: null,
    loggedIn: false,
    institutioner: [],
    udbydere: [],
    dagtilbud: [],
    notifikationer: [],
    bruger: {
      brugerid: null,
      navn: null,
      cvr: null,
      organisation: {
        udbyderId: null,
        kode: null,
        navn: null,
        cvr: null,
        pnr: null,
        erPassiveret: null,
        passiveretKommentar: null,
        type: null,
      },
      privilegier:{
        cvrNumre:[],
        pnumre:[],
      },
      stamdataCvr: null,
      stamdataPnr: null,
      flereOrganisationer: false,
      adgang: [],
      readonly: false,
      erSupport: false,
    }
  }
};
