var loginUrlPrefix = process.env.REACT_APP_LOGIN_URL_PREFIX || "";
module.exports = {
  urlLogin: loginUrlPrefix + '/tilslutning/login',
  urlLogout: loginUrlPrefix + '/tilslutning/logout',
  tokenExpiresHeader: 'Auth-Token-Expires',
  urlBruger: '/tilslutningBE/brugeroplysninger',
  apiHentNotifikationer: '/tilslutningBE/notifikationer/hent',
  // State
  loginStateInit: {
    token: null,
    loggedIn: false,
    bruger: {
      brugerid: null,
      navn: null,
      cvr: null,
      organisation: {
        udbyderId: null,
        kode: null,
        navn: null,
        cvr: null,
        pnr: null,
        erPassiveret: null,
        passiveretKommentar: null,
        type: null
      },
      privilegier: {
        cvrNumre: [],
        pnumre: []
      },
      stamdataCvr: null,
      stamdataPnr: null,
      flereOrganisationer: false,
      adgang: [],
      readonly: false,
      erSupport: false
    }
  },
  actions: {
    UPDATE_EXPIRES: "UPDATE_EXPIRES"
  }
};