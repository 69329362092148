import _objectSpread from "/data/jenkins/workspace/ILT/team_skolegrunddata/skolegrunddata/dataadgang/deploy_dataadgang/projects/tilslutning-forside/tilslutningFE/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import constants from './constants';
import { fetchJsonWithoutDispatch } from "./fetch"; // Hent login-oplysninger og notifikationer. Funktionen kaldes fra redux-persist migration i configureStore.js.

function hentBruger(state) {
  return fetchJsonWithoutDispatch(constants.urlBruger, "GET").then(function (bruger) {
    var loginState = state !== undefined ? state.login : undefined;
    var forsideState = state !== undefined ? state.forside : undefined;
    return _objectSpread({}, state, {
      login: _objectSpread({}, loginState, {
        bruger: bruger,
        loggedIn: true
      }),
      forside: _objectSpread({}, forsideState, {
        showSelectOrganisationModal: bruger.organisation === null
      })
    });
  }).catch(function () {
    return _objectSpread({}, state, {
      login: constants.loginStateInit
    });
  });
}

function hentBrugersNotifikationer(state, prevState) {
  if (notifikationerSkalHentes(state, prevState)) {
    return fetchJsonWithoutDispatch(constants.apiHentNotifikationer).then(function (notifikationer) {
      return _objectSpread({}, state, {
        login: _objectSpread({}, state.login, {
          notifikationer: notifikationer
        })
      });
    }).catch(function () {
      return _objectSpread({}, state, {
        login: _objectSpread({}, state.login, {
          notifikationer: []
        })
      });
    });
  } else {
    return _objectSpread({}, state);
  }
}

function notifikationerSkalHentes(state, prevState) {
  return state.login.loggedIn === true && state.login.bruger.organisation !== null && (prevState === undefined || prevState.login.loggedIn === false || state.login.bruger.organisation.kode !== prevState.login.bruger.organisation.kode || state.login.bruger.brugerid !== prevState.login.bruger.brugerid);
}

export default (function (prevState) {
  return hentBruger(prevState).then(function (state) {
    return hentBrugersNotifikationer(state, prevState);
  });
});