import constants from "../util/constants";
import {constants as commonConstants} from 'tilslutning-frontend-common';
import {fetchJson} from 'tilslutning-frontend-common';

export function loadForsideFrontendVersion() {
  return loadVersion(constants.forsideFrontendVersionUrl,
      constants.GET_FRONTEND_VERSION_SUCCESS,
      constants.GET_FRONTEND_VERSION_FEJL);
}

export function loadForsideBackendVersion() {
  return loadVersion(constants.forsideBackendVersionUrl,
      constants.GET_BACKEND_VERSION_SUCCESS,
      constants.GET_BACKEND_VERSION_FEJL);
}

export function loadUdbyderSystemAdmFrontendVersion() {
  return loadVersion(constants.udbydersystemadmFrontendVersionUrl,
      constants.GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_SUCCESS,
      constants.GET_UDBYDERSYSTEMADM_FRONTEND_VERSION_FEJL);
}

export function loadUdbyderSystemAdmBackendVersion() {
  return loadVersion(constants.udbydersystemadmBackendVersionUrl,
      constants.GET_UDBYDERSYSTEMADM_BACKEND_VERSION_SUCCESS,
      constants.GET_UDBYDERSYSTEMADM_BACKEND_VERSION_FEJL);
}

export function loadStilserviceAdmFrontendVersion() {
  return loadVersion(constants.stilserviceadmFrontendVersionUrl,
      constants.GET_STILSERVICEADM_FRONTEND_VERSION_SUCCESS,
      constants.GET_STILSERVICEADM_FRONTEND_VERSION_FEJL);
}

export function loadStilserviceAdmBackendVersion() {
  return loadVersion(constants.stilserviceadmBackendVersionUrl,
      constants.GET_STILSERVICEADM_BACKEND_VERSION_SUCCESS,
      constants.GET_STILSERVICEADM_BACKEND_VERSION_FEJL);
}

export function loadDataadgangAdmFrontendVersion() {
  return loadVersion(constants.dataadgangadmFrontendVersionUrl,
      constants.GET_DATAADGANGADM_FRONTEND_VERSION_SUCCESS,
      constants.GET_DATAADGANGADM_FRONTEND_VERSION_FEJL);
}

export function loadDataadgangAdmBackendVersion() {
  return loadVersion(constants.dataadgangadmBackendVersionUrl,
      constants.GET_DATAADGANGADM_BACKEND_VERSION_SUCCESS,
      constants.GET_DATAADGANGADM_BACKEND_VERSION_FEJL);
}

export function loadRestInstitutionAdmFrontendVersion() {
  return loadVersion(constants.restinstitutionadmFrontendVersionUrl,
      constants.GET_RESTINSTITUTIONADM_FRONTEND_VERSION_SUCCESS,
      constants.GET_RESTINSTITUTIONADM_FRONTEND_VERSION_FEJL);
}

export function loadRestInstitutionAdmBackendVersion() {
  return loadVersion(constants.restinstitutionadmBackendVersionUrl,
      constants.GET_RESTINSTITUTIONADM_BACKEND_VERSION_SUCCESS,
      constants.GET_RESTINSTITUTIONADM_BACKEND_VERSION_FEJL);
}

export function loadSupportvaerktoejFrontendVersion() {
  return loadVersion(constants.supportvaerktoejFrontendVersionUrl,
    constants.GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_SUCCESS,
    constants.GET_SUPPORTVAERKTOEJ_FRONTEND_VERSION_FEJL);
}
export function loadSupportvaerktoejBackendVersion() {
  return loadVersion(constants.supportvaerktoejBackendVersionUrl,
    constants.GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_SUCCESS,
    constants.GET_SUPPORTVAERKTOEJ_BACKEND_VERSION_FEJL);
}

function loadVersion(url, successAction, errorAction) {
  return (dispatch) => {
    fetchJson(url, "GET", undefined, dispatch)
        .then(json => dispatch({ type: successAction, version: json }))
        .catch(errorResponse => dispatch({ type: errorAction }));
  };
}
