import React, {Component} from 'react'
import {Link, Redirect} from "react-router-dom";
import AppLinks from "../pages/AppLinks";
import LoginBar from "../pages/LoginBar";
import SortingChevron from "../util/SortingChevron";
import queryString from "query-string";
import {AlertBar, SessionModal} from 'tilslutning-frontend-common';
import {Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LoginModal from "../modals/LoginModal";
import constants from '../util/constants';
import OrganisationErrorAlert from "../util/OrganisationAlert";

class ForsideMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSort: 'instregnr',
            sortDirection: 'desc',
            organisationer: [],
            searching: false,
            search: "",
            confirmOrganisation: null,
            activeTab: "INSTITUTIONER",
            institutionerHentet: false,
            restinstitutionerHentet: false,
            udbydereHentet: false,
            dagtilbudHentet: false,
            showBasicLoginInfo: false,
            notifikationerHentet: false,
        };
    }

    componentDidMount() {
        const {
            login, hentNotifikationer,
            hentInstitutioner,
            hentRestOrganisationer,
            hentUdbydere,
            hentDagtilbud,
            fornyJwt
        } = this.props;
        const queryParams = queryString.parse(window.location.search);
        const notifikationsemailUuid = queryParams[constants.bekraeftNotifikationsemailParam];

        if (notifikationsemailUuid)
            this.props.bekraeftNotifikationsemail(notifikationsemailUuid);

        const kontaktpersonUuid = queryParams[constants.fornyKontaktpersonParam];
        if (kontaktpersonUuid)
            this.props.fornyKontaktperson(kontaktpersonUuid);

        if (login.loggedIn && login.bruger.organisation != null && !this.state.notifikationerHentet) {
            hentNotifikationer();
            this.setState({notifikationerHentet: true})
        }

        if (login.loggedIn && !this.state.institutionerHentet) {
            hentInstitutioner();
            this.setState({institutionerHentet: true})
        }

        if (login.loggedIn && !this.state.restinstitutionerHentet) {
            hentRestOrganisationer();
            this.setState({restinstitutionerHentet: true})
        }

        if (login.loggedIn && !this.state.udbydereHentet) {
            hentUdbydere();
            this.setState({udbydereHentet: true})
        }

        if (login.loggedIn && !this.state.dagtilbudHentet) {
            hentDagtilbud();
            this.setState({dagtilbudHentet: true})
        }

        if (localStorage.getItem(constants.BRUGER) || localStorage.getItem(constants.ORGANISATION)) {
            localStorage.clear();
        }

        if (login.loggedIn && !login.expires) {
            fornyJwt();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            login,
            forside,
            nulstilAlerts,
            institutioner,
            udbydere,
            dagtilbud,
            restOrganisationer,
            hentNotifikationer
        } = this.props;
        if (this.state.expires !== login.expires)
            this.setState({expires: login.expires});

        if (forside.showSessionAlertModal)
            clearInterval(this.state.interval);

        if (prevProps.forside.showSelectOrganisationModal !== forside.showSelectOrganisationModal && !forside.showSelectOrganisationModal) {
            nulstilAlerts();
            this.setState({notifikationerHentet: false})
        }

        if (this.state.activeTab === "INSTITUTIONER" && prevProps.institutioner !== institutioner) {
            this.setState({organisationer: institutioner.organisationer});
        }

        if (this.state.activeTab === "UDBYDERE" && prevProps.udbydere !== udbydere) {
            this.setState({organisationer: udbydere.organisationer});
        }

        if (this.state.activeTab === "RESTINSTITUTIONER" && prevProps.restOrganisationer !== restOrganisationer) {
            this.setState({organisationer: restOrganisationer.organisationer});
        }

        if (this.state.activeTab === "DAGTILBUD" && prevProps.dagtilbud !== dagtilbud) {
            this.setState({organisationer: dagtilbud.organisationer});
        }

        if (login.loggedIn && login.bruger.organisation != null && !this.state.notifikationerHentet) {
            hentNotifikationer();
            this.setState({notifikationerHentet: true})
        }

    }

    render() { //NOSONAR
        const {
            forside,
            login,
            loginClear,
            selectOrganisation,
            openSelectOrganisationModal,
            institutioner,
            restOrganisationer,
            udbydere,
            dagtilbud,
            fornyJwt,
            skjulLoginModal,
            visLoginModal,
            basicLogin,
            notifikationer
        } = this.props;
        const queryParams = queryString.parse(window.location.search);

        // Logout skal rydde login-information fra eventuel gemt state.
        if (queryParams[constants.postLogoutParam] !== undefined) {
            loginClear();
            return <Redirect to="/"/>;
        }

        if (queryParams[constants.selectOrganisationParam] !== undefined) {
            openSelectOrganisationModal();
            return <Redirect to="/"/>;
        }

        if (forside.showSelectOrganisationModal) {
            const sortedOrganisationer = this.state.organisationer ? this.state.organisationer.sort((a, b) => {
                if (this.state.searching)
                    return -1;
                if (this.state.activeSort === "instregnr")
                    return a.kode.localeCompare(b.kode);
                if (this.state.activeSort === "navn")
                    return a.navn.localeCompare(b.navn);
                if (this.state.activeSort === "cvr")
                    return !a.cvr ? -1 : !b.cvr ? 1 : a.cvr.toString().localeCompare(b.cvr.toString());
                if (this.state.activeSort === "p-nr")
                    return !a.pnr ? -1 : !b.pnr ? 1 : a.pnr.toString().localeCompare(b.pnr.toString());
                return 0;
            }) : [];

            if (this.state.sortDirection === "asc")
                sortedOrganisationer.reverse();

            const aktiveOrganisationer = this.state.activeTab === "INSTITUTIONER" ? institutioner :
                this.state.activeTab === "UDBYDERE" ? udbydere :
                    this.state.activeTab === "RESTINSTITUTIONER" ? restOrganisationer : dagtilbud;

            const henterInstitutioner = this.state.institutionerHentet && !this.state.searching && !institutioner;
            const henterRestinstitutioner = this.state.restinstitutionerHentet && !this.state.searching && !restOrganisationer;
            const henterUdbydere = this.state.udbydereHentet && !this.state.searching && !udbydere;
            const henterOrganisationer = (this.state.activeTab === "INSTITUTIONER" && henterInstitutioner) ||
                (this.state.activeTab === "UDBYDERE" && henterUdbydere) ||
                (this.state.activeTab === "RESTINSTITUTIONER" && henterRestinstitutioner)
            ;

            return <Modal isOpen={forside.showSelectOrganisationModal} className="organisationer-modal">
                <ModalHeader>Vælg aktiv organisation</ModalHeader>
                <ModalBody className="organisationer-modal-body">
                    <AlertBar alerts={forside.alerts}/>
                    <Input type="search" id="organisation-search" placeholder="Søg ..." value={this.state.search}
                           onChange={(event) => search(this, event.target.value, aktiveOrganisationer.organisationer)}/>

                    <div id="modal-top" className="clearfix"/>

                    {
                        this.state.confirmOrganisation ?
                            <div className="organisation-confirm">
                                <strong>Du er ved at skifte til en anden organisation.</strong>
                                <p>Hvis du fortsætter vil du agere på vegne
                                    af <b>{this.state.confirmOrganisation.navn}</b>.</p>
                                <div className="button-float-right">
                                    <button className="stil-primary-button"
                                            onClick={() => this.setState({confirmOrganisation: null})}>Annuller
                                    </button>
                                    <button className="stil-primary-button" onClick={() => {
                                        this.setState({
                                            searching: false,
                                            organisationer: aktiveOrganisationer.organisationer,
                                            confirmOrganisation: null,
                                            search: ""
                                        });
                                        selectOrganisation(this.state.confirmOrganisation);
                                    }
                                    }>Bekræft
                                    </button>
                                </div>

                            </div> : null
                    }


                    <div className="full-width">
                        <button id="institutioner-tab-buttom"
                                className={this.state.activeTab === "INSTITUTIONER" ? "tab-button-selected" : "tab-button"}
                                onClick={() => {
                                    document.getElementById("organisation-search").focus();
                                    this.setState({
                                        activeTab: "INSTITUTIONER",
                                        organisationer: institutioner.organisationer,
                                        searching: false
                                    });
                                    search(this, document.getElementById("organisation-search").value, institutioner.organisationer);
                                }}>
                            Institutioner
                        </button>

                        <button id="udbyder-tab-buttom"
                                className={this.state.activeTab === "UDBYDERE" ? "tab-button-selected" : "tab-button"}
                                onClick={() => {
                                    document.getElementById("organisation-search").focus();
                                    this.setState({
                                        activeTab: "UDBYDERE",
                                        organisationer: udbydere.organisationer
                                    });
                                    search(this, document.getElementById("organisation-search").value, udbydere.organisationer);
                                }}>
                            Udbydere
                        </button>

                        <button id="dagtilbud-tab-button"
                                className={this.state.activeTab === "DAGTILBUD" ? "tab-button-selected" : "tab-button"}
                                onClick={() => {
                                    document.getElementById("organisation-search").focus();
                                    this.setState({
                                        activeTab: "DAGTILBUD",
                                        organisationer: dagtilbud.organisationer
                                    });
                                    search(this, document.getElementById("organisation-search").value, dagtilbud.organisationer);
                                }}>
                            Dagtilbud
                        </button>

                        <button id="rest-tab-button"
                                className={this.state.activeTab === "RESTINSTITUTIONER" ? "tab-button-selected" : "tab-button"}
                                onClick={() => {
                                    document.getElementById("organisation-search").focus();
                                    this.setState({
                                        activeTab: "RESTINSTITUTIONER",
                                        organisationer: restOrganisationer.organisationer
                                    });
                                    search(this, document.getElementById("organisation-search").value, restOrganisationer.organisationer);
                                }}>
                            Andre
                        </button>

                    </div>

                    {henterOrganisationer ?
                        <h4>Henter organisationer ...</h4> :
                        <>
                            <table className="stil-tabel organisation-tabel">
                                <tr>
                                    <th className="hand organisation-code-header"
                                        onClick={() => this.setState({
                                            activeSort: "instregnr",
                                            sortDirection: this.state.activeSort === "instregnr" && this.state.sortDirection === "asc" ? "desc" : "asc"
                                        })}>
                                        {this.state.activeTab === "UDBYDERE" ? "Udbydernr" : "Instregnr"}
                                        <SortingChevron active={this.state.activeSort === 'instregnr'}
                                                        direction={this.state.sortDirection}/>
                                    </th>
                                    <th className="hand organisation-name-header"
                                        onClick={() => this.setState({
                                            activeSort: "navn",
                                            sortDirection: this.state.activeSort === "navn" && this.state.sortDirection === "asc" ? "desc" : "asc"
                                        })}>
                                        Navn
                                        <SortingChevron active={this.state.activeSort === 'navn'}
                                                        direction={this.state.sortDirection}/>
                                    </th>
                                    <th className="hand organisation-cvr-header"
                                        onClick={() => this.setState({
                                            activeSort: "cvr",
                                            sortDirection: this.state.activeSort === "cvr" && this.state.sortDirection === "asc" ? "desc" : "asc"
                                        })}>
                                        CVR
                                        <SortingChevron active={this.state.activeSort === 'cvr'}
                                                        direction={this.state.sortDirection}/>
                                    </th>
                                    <th className="hand organisation-pnr-header"
                                        onClick={() => this.setState({
                                            activeSort: "p-nr",
                                            sortDirection: this.state.activeSort === "p-nr" && this.state.sortDirection === "asc" ? "desc" : "asc"
                                        })}>
                                        P-nr.
                                        <SortingChevron active={this.state.activeSort === 'p-nr'}
                                                        direction={this.state.sortDirection}/>
                                    </th>
                                </tr>
                                {
                                    sortedOrganisationer.map((organisation) => {
                                        return (
                                            <tr className="hand organisation-table-row" onClick={() => {
                                                if (!organisation.cvr || organisation.cvr.toString() !== login.bruger.stamdataCvr.toString()) {
                                                    this.setState({confirmOrganisation: organisation});
                                                    document.getElementById("modal-top").scrollIntoView({block: 'end'});
                                                } else {
                                                    this.setState({
                                                        searching: false,
                                                        search: "",
                                                        organisationer: aktiveOrganisationer.organisationer,
                                                        confirmOrganisation: null
                                                    });
                                                    selectOrganisation(organisation);
                                                }
                                            }}>
                                                <td className="organisation-kode">{organisation.kode}</td>
                                                <td className="organisation-navn">{organisation.navn}</td>
                                                <td className="organisation-cvr">{organisation.cvr}</td>
                                                <td className="organisation-pnr">{organisation.pnr}</td>
                                            </tr>
                                        )
                                    })}
                            </table>
                            <div>

                                {
                                    this.state.activeTab === "INSTITUTIONER" && institutioner.instregError ?
                                        <OrganisationErrorAlert error={institutioner.instregError}/> : null
                                }
                                {
                                    this.state.activeTab === "UDBYDERE" && udbydere.udbyderregError ?
                                        <OrganisationErrorAlert error={udbydere.udbyderregError}/> : null
                                }
                                {
                                    this.state.activeTab === "RESTINSTITUTIONER" && restOrganisationer.restregError ?
                                        <OrganisationErrorAlert error={restOrganisationer.restregError}/> : null
                                }
                                {
                                    this.state.activeTab === "DAGTILBUD" && dagtilbud.dagregError ?
                                        <OrganisationErrorAlert error={dagtilbud.dagregError}/> : null
                                }
                            </div>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Link to={constants.urlLogout} className="stil-primary-button">Log ud</Link>
                </ModalFooter>
            </Modal>
        }

        return (
            <div className="stil-main-content">
                <LoginBar loggedIn={login.loggedIn} bruger={login.bruger}
                          openSelectOrganisationModal={openSelectOrganisationModal}
                          selectOrganisation={selectOrganisation}
                          alerts={forside.alerts}
                          notifikationer={notifikationer}/>
                <AppLinks forside={forside} login={login}/>

                {login.loggedIn ? null : (
                    <div className="forside-tekst">
                        <p>
                            En tilslutning gør det muligt at sende eller hente data ved hjælp af STILs webservices.
                            Det kan f.eks. dreje sig om brug af UNI-Login, indberetninger af karakterer eller afhentning
                            af ansøgninger.
                        </p>

                        <p>
                            For at oprette en tilslutning skal der indgås to aftaler.
                            Der skal oprettes en aftale imellem udbyder og STIL (tilslutningsaftale) og en aftale
                            imellem udbyder og skolen (dataaftale).
                        </p>

                        <p>
                            Din skole eller virksomhed skal have et CVR-nummer, og du skal have et
                            MitID Erhverv for at kunne logge på Tilslutning.
                        </p>

                        <p>
                            <a className="hand"
                               onClick={() => this.setState({showBasicLoginInfo: !this.state.showBasicLoginInfo})}>
                                Sådan kan du logge ind, hvis du ikke har dansk CVR-nummer.
                            </a>
                        </p>

                        {
                            this.state.showBasicLoginInfo ?
                                <div>
                                    <p>
                                        Trin 1: Kontakt <a target="_blank" rel="noopener noreferrer"
                                                           href="https://jira.stil.dk/servicedesk/customer/portal/7/create/173"
                                                           title="Åbner en formular, hvor du kan skrive til STIL">STIL
                                        support</a> for at blive oprettet som bruger.
                                    </p>
                                    <p>
                                        Trin 2: Når du er blevet oprettet, kan du logge på her: <button
                                        className="button stil-primary-button" onClick={visLoginModal}>Log ind</button>
                                    </p>
                                </div>
                                : null
                        }
                    </div>
                )}

                <SessionModal login={login} fornySession={fornyJwt}/>

                <LoginModal visModal={forside.showLoginModal}
                            lukModal={skjulLoginModal}
                            login={basicLogin}
                            basicLoginFejl={forside.basicLoginFejl}/>
            </div>
        )
    }
}

function search(page, searchstring, organisationer) {
    const query = searchstring.toLowerCase();

    if (!query) {
        page.setState({
            searching: false,
            organisationer: organisationer,
            search: query
        });
        return;
    }

    if (organisationer == null) {
        page.setState({
            searching: true,
            search: query,
            organisationer: organisationer
        })
    } else {
        page.setState({
            searching: true,
            search: query,
            organisationer: organisationer
                .filter(organisation =>
                    organisation.kode.toLocaleLowerCase().search(query) !== -1 || //NOSONAR
                    organisation.navn.toLocaleLowerCase().search(query) !== -1 ||
                    (organisation.pnr && organisation.pnr.toString().search(query) !== -1) ||
                    (organisation.cvr && organisation.cvr.toString().search(query) !== -1)
                )
                .sort((a, b) => {
                    const aSearchValue = Math.min([a.kode.toLocaleLowerCase().search(query),
                        a.navn.toLocaleLowerCase().search(query),
                        (a.pnr ? a.pnr.toString().search(query) : -1),
                        (a.cvr ? a.cvr.toString().search(query) : -1)].filter(searchValue => searchValue !== -1));

                    const bSearchValue = Math.min([b.kode.toLocaleLowerCase().search(query),
                        b.navn.toLocaleLowerCase().search(query),
                        (b.pnr ? b.pnr.toString().search(query) : -1),
                        (b.cvr ? b.cvr.toString().search(query) : -1)].filter(searchValue => searchValue !== -1));

                    return aSearchValue - bSearchValue;
                })

        })
    }
}

export default ForsideMain;
