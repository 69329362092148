import './css/main.css';
import './css/stil-baseline.css';
import'babel-polyfill';
import 'whatwg-fetch';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import configureStore from "./store/configureStore";
import {AlertBar} from 'tilslutning-frontend-common';
import constants from './util/constants';

const state = {
  myInitState: {
    login: constants.loginStateInit,
    forside: {
      previousType: "",
      status: false,
      alerts: {fejl: [], info: []},
      showSelectOrganisationModal: false,
      showLoginModal: false,
      basicLoginFejl: null
    }
  },
};

state.myInitState.forside.alerts = AlertBar.getAlertsFromQuery();

const { store, persistor } = configureStore(state.myInitState);

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/tilslutning">
        <App/>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('react')
);
