import constants from "../util/constants";
import {constants as commonConstants} from 'tilslutning-frontend-common';

export function loginReducer(state = [], action) {

  switch (action.type) {
    case commonConstants.actions.UPDATE_EXPIRES:
      return {...state, expires: action.expires}

    case constants.FORNY_JWT_SUCCESS:
      return {...state, bruger: action.bruger, status: constants.status_forny_jwt_successs};

    case constants.BASIC_LOGIN_SUCCESS:
      return {...state, bruger: action.bruger, expires: action.expires, token: action.jwt, loggedIn: true, status: constants.status_basic_login_success};

    case constants.LOGIN_CLEAR:
      return constants.loginStateInit;

    case constants.SELECT_ORGANISATION:
      return {...state, bruger: action.bruger, token: action.jwt};


    case constants.HENT_INSTITUTIONER_SUCCESS: {
      return {...state, institutioner: action.institutioner};
    }

    case constants.HENT_INSTITUTIONER_FEJL:
      return {...state, alerts: action.alerts};

    case constants.HENT_REST_ORGANISATIONER_SUCCESS: {
      return {...state, restOrganisationer: action.restOrganisationer};
    }

    case constants.HENT_REST_ORGANISATIONER_FEJL:
      return {...state, alerts: action.alerts};


    case constants.HENT_UDBYDERE_SUCCESS: {
      return {...state, udbydere: action.udbydere, sidsteUdbyderSide: action.sidsteSide, udbyderSide: action.side};
    }

    case constants.HENT_UDBYDERE_FEJL:
      return {...state, alerts: action.alerts};

    case constants.HENT_DAGTILBUD_SUCCESS: {
      return {...state, dagtilbud: action.dagtilbud};
    }

    case constants.HENT_DAGTILBUD_FEJL:
      return {...state, alerts: action.alerts};

    case constants.HENT_NOTIFIKATIONER_SUCCESS: {
      return {...state, notifikationer: action.notifikationer};
    }

    case constants.HENT_NOTIFIKATIONER_FEJL:
      return {...state, alerts: action.alerts};

    default:
      return state;
  }
}
